@import '../../node_modules/antd/dist/antd.css';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f0f2f5;
}

.header {
  text-align: center;
  color: #fff;
  width: 100%;
}

.header-title {
  font-size: 28px;
}

.site-layout {
  padding: 20px;
}

.inner-content {
  max-width: 900px;
  margin: 0 auto;
}

.form-box {
  background: #fff;
  padding: 24px;
  margin-bottom: 24px;
}


.ant-form-item-label{
  font-weight: bold;
}

.point {
  display: inline-block;
  font-style: italic;
  font-weight: bold;
  font-size: 14px;
  margin-left: 5px;
}

.collapse-wrapper {
  margin-bottom: 16px;
}

.question-row:not(:last-child) {
  margin-bottom: 16px;
}

.sub-question-row {
  padding: 12px;
  margin: 12px 12px 12px 25px;
  background: #f0f2f5;
}
